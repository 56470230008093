$(document).on('click', '#footer .headline', function (e) {
  var linksContents, me, meVisible;
  if (root.isMobileScreen()) {
    e.preventDefault();
    me = $(this);
    linksContents = me.parents('.menu-section').find('.links-contents');
    meVisible = linksContents.is(':visible');
    $('#footer .headline .status-icon').removeClass('icon-minus-center');
    $('#footer .headline .status-icon').addClass('icon-plus-center');
    $('#footer .links .links-contents:visible').slideUp();
    if (!meVisible) {
      me.find('.status-icon').removeClass('icon-plus-center');
      me.find('.status-icon').addClass('icon-minus-center');
      return linksContents.slideDown();
    }
  }
});

$(document).on('click', '.bottom-footer-contents .awards .mt-col', function() {
  var awards, awardsExplanation;
  awards = $('.awards');
  awardsExplanation = $('.awards-explanation');
  if ($(this).hasClass('active')) {
    awardsExplanation.addClass('hidden');
    $(this).removeClass('active');
  } else {
    awardsExplanation.find('.award-desc:not(.hidden)').addClass('hidden');
    awardsExplanation.find(".award-desc#" + ($(this).data('target'))).removeClass('hidden');
    awards.find('.active').removeClass('active');
    $(this).addClass('active');
    awardsExplanation.removeClass('hidden');
    $('html, body').animate({
      scrollTop: $(document).height()
    }, 'slow');
  }
});
