$(document).ready(function(){
  if($('body#product').length){
    /**
     * TM - Variants select
     */
    var variantSelect = $('#product #variant_select');
    var priceHolder = $('#product .prod-price .price');
    var selected = variantSelect.find('option:selected');
    var price = selected.data('price');
    price && priceHolder.text(price);

    function showWaitlistEmailError(errorText) {
      $('#notify_details .error-msg').text(errorText)
      $('#notify_details .error-msg').slideDown()
      setTimeout(() => {
        $('#notify_details .error-msg').slideUp()
      }, 5000)
    }

    $('#product').on('click', '#notify_email_btn', function(e){
      const user_email = $('#notify_email').val()?.trim()
      if(user_email.length == 0 || !user_email.includes('@')) {
        showWaitlistEmailError('Please enter a valid e-mail')
        return
      }
      let modal = $('#product_notify_modal')
      const data = {
        product_id: modal.data('product'),
        product_variant_id: modal.data('variant'),
        user_email: user_email,
        user_id: modal.data('user')
      }
      const waitlistURL = `${window.STORE_BASE_URL}/waitlists/create`;
      $.post(waitlistURL, data, function(res){
        $('#notify_success').css('display', 'block')
        $('#notify_details').css('display', 'none')
      }).fail(({ responseJSON })=> {
        showWaitlistEmailError(responseJSON.message)
      })
    });

    $('#product').on('change', '#variant_select', function(e){
      var selected = $(this).find('option:selected');
      if(selected.hasClass('out_of_stock_variant')) {
        $('#product #variant_select').val(null)
        let modal = $('#product_notify_modal')
        modal.css('display', 'block')
        modal.data('product', $('.add-to-cart').data('product'))
        modal.data('variant', selected.data('id'))
        return
      }
      if(selected.val()) {
        $('.product-variant-decrement').attr('disabled', false)
        $('.product-variant-increment').attr('disabled', false)
        $('.add-to-cart').attr('disabled', false)
      }
      var price = selected.data('price');
      price && priceHolder.text(price);
      $(".variant-main-image").each(function(i, obj) {
        if($(obj).data('id') === selected.data('id')) {
          $('.images-slides ul').slick('slickGoTo', $(obj).attr('data-slick-index'), true);
        }
      });
    });
  }

  $('body').on('click', '#close_notify_modal', function(e){
    $('#product_notify_modal').css('display', 'none')
  })
});
