import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;
window.root = jquery;
window.STORE_BASE_URL = `${$("body").data("store-url")}`;
this.root = typeof exports !== "undefined" && exports !== null ? exports : this;

root.isMobileScreen = function () {
  return $(".visible-xs").is(":visible");
};

root.isProductionEnv = function () {
  return $("body").data("environment") === "production";
};
const adjustFooterHeight = function() {
  $('main').css('margin-bottom', `${$('footer').outerHeight()}px`)
}

$(window).on('resize', function() {
  if (!root.isMobileScreen())
    adjustFooterHeight()
})

$(document).ready(function () {
  if (!root.isMobileScreen())
    adjustFooterHeight()
  $("#header .left-menu a").on("click", function () {
    $("#overlay-container").show();
    $("body").addClass("side-menu-push-toright");
    $(".side-menu").addClass("side-menu-open");
  });
  $("#overlay-container").on("click", function () {
    $("#overlay-container").hide();
    $("body").removeClass("side-menu-push-toright");
    $(".side-menu").removeClass("side-menu-open");
  });

  $(".logout").on("click", function (e) {
    if (!confirm("By continuing you will be logged out from the system.", 'Yes')) {
      e.preventDefault();
    }
  });

  if ($(".flash.alert").length) {
    setTimeout(() => {
      var flashDiv = $(".flash.alert");
      flashDiv.hide("slow");
    }, 5000);
  }

  const debounce = (func, delay) => {
    let debounceTimer
    return function() {
      const context = this
      const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
  }

  $('#product-navbar-search').on('keyup', function(event){
    checkKeysAndSendRequest(event, $(this).val(), 'navbar')
  })

  $('#product-search').on('keyup', function(event){
    checkKeysAndSendRequest(event, $(this).val(), 'homePage')
  })

  function checkKeysAndSendRequest(event, searchText, searchInputType){
    let unPermittedKeys = ['Shift', 'CapsLock', 'Control', 'Alt', 'Meta']
    if (event.key === 'Escape'){
      $('.top-nav-search-container').hide()
      $('.top-nav-inner').show();
      $('#product-search').blur();
      $('.search-suggetion-cross-button').hide()
      $('.product-search-button').show();
      $('.product-suggetion-container').empty();
    } else if (!unPermittedKeys.includes(event.key)){
      addDeboucing(searchText, searchInputType);
    }
  }

  const addDeboucing = debounce( (searchText, searchInputType) => {
    const url = window.STORE_BASE_URL+'/search';
    const searchParameter = searchText
    let category_ids = 'allProducts'
    $.get( url, { search: searchParameter, suggetionSearch: true, category_ids: category_ids }, function(response){
      if (searchInputType === 'navbar'){
        $('.product-navbar-suggetion-container').empty();
        $('.product-navbar-suggetion-container').append(response)
      } else {
        $('.product-suggetion-container').empty();
        $('.product-suggetion-container').append(response)
      }
    })
  }, 500)

  $('.search-cross-button').on('click', function(){
    $('.top-nav-search-container').hide()
    $('.top-nav-inner').show();
  })

  $('.nav-search').on('click', function(){
    $('.top-nav-inner').hide();
    $('.top-nav-search-container').show()
    $('.top-nav-search-container input').focus()
  })
});

function showAjaxError(res) {
  if (res.message) {
    alert(res.message);
  } else {
    alert("Something went wrong! Please try again later.");
  }
}
