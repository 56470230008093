if(typeof Stripe !== 'undefined') {  
  // A reference to Stripe.js initialized with your real test publishable API key.
  const publishedKey = document.getElementById("payment-form").getAttribute('data-stripe-published-key')
  var stripe = Stripe(publishedKey);

  // Disable the button until we have Stripe set up on the page
  document.querySelector("button").disabled = true;
  // Either we can fetch cleint secret or use server side rendring to load client secret
  // fetch("/create-payment-intent", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json"
  //   },
  //   body: JSON.stringify(purchase)
  // })
  //   .then(function(result) {
  //     return result.json();
  //   })
  //   .then(function(data) {
  // });

  // Here we load clientSecret from data attribute of form
  const clientSecret = document.getElementById("payment-form").getAttribute('data-secret')
  if(clientSecret) {
    const appearance = {
      theme: 'stripe',
    };
    var elements = stripe.elements({ appearance, clientSecret });

    const paymentElementOptions = {
      layout: "tabs",
    };
    var paymentElement = elements.create("payment", paymentElementOptions);
    // Stripe injects an iframe into the DOM
    paymentElement.mount("#payment-element");
    
    paymentElement.on('ready', () => {
      document.querySelector("button").disabled = false;
    });

    // paymentElement.on("change", function (event) {
    //   console.log('paymentElement change')
    //   console.log(event)
    //   // Disable the Pay button if there are no card details in the Element
    //   document.querySelector("button").disabled = event.empty;
    //   if (event.value.type=="swish")
    //     document.querySelector("button").disabled = false;
    //   document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
    // });
    
    var form = document.getElementById("payment-form");
    form.addEventListener("submit", function(event) {
      event.preventDefault();
      // verify if session is not expired
      fetch(document.getElementById("payment-form").getAttribute('data-auth-url'))
      .then(res => {
        if(res.redirected && window.confirm('Session expired, you need to login again.')) {
          window.location.href = res.url
        } else {
          // Complete payment when the submit button is clicked
          payWithCard(stripe, elements, clientSecret);
        }
      })
    });
  }
    
  // Calls stripe.confirmCardPayment
  // If the card requires authentication Stripe shows a pop-up modal to
  // prompt the user to enter authentication details without leaving your page.
  var payWithCard = function(stripe, elements, clientSecret) {
    loading(true);
    stripe
    .confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: document.getElementById("payment-form").getAttribute('data-complete-url')
      },
    })
    .then(function(result) {
      if (result.error) {
        // Show error to your customer
        showError(result.error.message);
      }
    });
  };

  /* ------- UI helpers ------- */

  // Shows a success message when the payment is complete
  var orderComplete = function(paymentIntentId) {
    loading(false);
    
    document.querySelector(".payment-message").classList.remove("hidden");
    document.querySelector("button").disabled = true;
  };

  // Show the customer the error from Stripe if their card fails to charge
  var showError = function(errorMsgText) {
    loading(false);
    var errorMsg = document.querySelector("#payment-error");
    errorMsg.textContent = errorMsgText;
    setTimeout(function() {
      errorMsg.textContent = "";
    }, 12000);
  };

  // Show a spinner on payment submission
  var loading = function(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("button").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("button").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  };
}
