import './jquery.validate.min.js';
import './slick.min.js';
import './common.js';
import './front-page.js';
import './categories.js';
import './product_images.js';
import './products.js';
import './cart.js';
import './payment.js';
import './footer.js';
import './account.js';
import './home.js';