$(document).ready(function(){
  
  
  $('body').on('click', '#profile .add-address', function(e){
    e.preventDefault()
    const url = $(this).data('url')
    const target = $(this).attr('href')
    $.get(url, function(res) {
      $(target).html(res)
      $(target).slideDown( function(){
        $([document.documentElement, document.body]).animate({
          scrollTop: $(target).offset().top
      }, 500);
      })
    })
  })

  $('body').on('click', '#profile #address-form .close-address-form', function(e){
    e.preventDefault()
    
    $(this).parents('.address-container').parent().slideUp()
  })

  $('body').on('click', '#profile .delete-address', function(e){
    e.preventDefault()
    if(window.confirm('Are you sure you want to delete this address?'))
      $(this).parents('.delete-address-form').trigger('submit')
  })

})
