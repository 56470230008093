import { HttpClient } from "./httpClient"

$(document).ready(function(){
  var searchInput = $('#product-main-search')
  var sortSelect = $('#product-sorting-select')
  var categoryFilters = $('.category-filter')
  var allCategoryFilter = $('.all-category-filter')
  var loadMoreButton = $('#load-more-button')
  var moreMoreButtonContainer = $('.load-more-container')

  $('body').on('submit', "#product-suggetion-form", function(eventObj) {
    $(this).append('<input type="hidden" name="category_ids" value="allProducts" /> ');
    return true;
  })

  sortSelect.on('change', function(event) {
    let page = $(this)[0].name
    let url = ( page === 'product_sorting' ? window.STORE_BASE_URL : `${window.STORE_BASE_URL}/search`)
    let search_parameter = searchInput.val();
    const categoryFilterIds = getCategoryIds('sort_select', false)
    let queryParams = { sort_by: this.value, search: search_parameter, category_ids: categoryFilterIds }
    HttpClient.get( url , queryParams, function (response) {
      showResult(response)
    });
  });

  allCategoryFilter.on('change', function(event) {
    var categoryFilterIds = null;
    if ((allCategoryFilter).prop('checked')){
      categoryFilterIds = getCategoryIds('all_category_filter', true)
    } else {
      getCategoryIds('all_category_filter', false)
    }
    const url = `${window.STORE_BASE_URL}/search`
    const search_parameter = searchInput.val();
    const sort_by_parameter = sortSelect.val()
    const queryParams = { sort_by: sort_by_parameter, search: search_parameter, category_ids: categoryFilterIds }
    console.log(queryParams)
    HttpClient.get( url , queryParams, function (response) {
      showResult(response)
      $('.total-search-count').text(response.totalCount)
    })
  })

  categoryFilters.on('change', function(event) {
    const url = `${window.STORE_BASE_URL}/search`
    const search_parameter = searchInput.val();
    const categoryFilterIds = getCategoryIds('single_category_filter', false)
    const sort_by_parameter = sortSelect.val()
    const queryParams = { sort_by: sort_by_parameter, search: search_parameter, category_ids: categoryFilterIds }
    HttpClient.get( url , queryParams, function (response) {
      showResult(response)
      $('.total-search-count').text(response.totalCount)
    })
  })

  function getCategoryIds(type, allCategorySelect){
    let categoryFilterIds = []
    let selectedCategories = $('.category-filter:checked')
    if (type === 'all_category_filter'){
      categoryFilters.each(function(){
        $(this).prop('checked', allCategorySelect)
        allCategorySelect && (categoryFilterIds.push($(this).data('categoryId')))
      })
      if (allCategorySelect){
        return categoryFilterIds;
      } else {
        return null;
      }
    } else {
      selectedCategories?.each(function(){
        categoryFilterIds.push($(this).data('categoryId'))
      });
      if (categoryFilters.length !== selectedCategories.length){
        allCategoryFilter.prop('checked', false)
      } else {
        allCategoryFilter.prop('checked', true)
      }
      if (selectedCategories.length === 0){
        return null
      } else {
        return categoryFilterIds
      } 
    }
  }

  function showResult(response){
    $('.home-products-container .products-partial-container').remove()
    $('.home-products-container .load-more-products-container').empty()
    $('.home-products-container').prepend(response.productView);
    if (response.totalCount > response.perPage){
      if (moreMoreButtonContainer[0].style.display === 'none') {
        moreMoreButtonContainer.show()
      }
      loadMoreButton.data('page', 2);
      loadMoreButton.data('perPage', response.perPage);
      loadMoreButton.data('totalProducts', response.totalCount);
      $('.show-more-count').text(`(${response.totalCount - response.perPage})`);
    } else {
      moreMoreButtonContainer.hide();
    }
  }

  $('#product-search').on('focus', function(){
    $('.search-suggetion-cross-button').show()
    $('.product-search-button').hide()
  })

  $('.search-suggetion-cross-button').on('click', function(){
    $('.search-suggetion-cross-button').hide()
    $('.product-search-button').show();
    $('.product-suggetion-container').empty();
  })

  $('body').on('click', '#load-more-button', function(e){
    const url = window.STORE_BASE_URL+'/home-products';
    let pageCount = $(this).data('page');
    let perPage = $(this).data('perPage');
    let totalProducts = $(this).data('totalProducts');
    let homePageLoadMore = $(this).data('homePage');
    let search_parameter = searchInput.val()
    let sort_by_parameter = sortSelect.val()
    const categoryFilterIds = homePageLoadMore ? 'allProducts' : getCategoryIds('load_more', false)
    let queryParams = { page: pageCount, sort_by: sort_by_parameter, search: search_parameter, category_ids: categoryFilterIds, homePageLoadMore: homePageLoadMore }
    HttpClient.get( url, queryParams, function(response) {
      $('.load-more-products-container').append(response)
      loadMoreButton.data('page', pageCount+1);
      var products_loaded = pageCount * perPage;
      $('.show-more-count').text(`(${totalProducts - products_loaded})`);
      if (products_loaded >= totalProducts ){
        moreMoreButtonContainer.hide();
      }
    })
  })
})