$(document).ready(function () {
  // Load cart count on page load
  if ($('body').find('.cart-link .cart-count').length)
    fetchCartCount(); 

  if ($('body#product').length) {
    /**
     * TM - Add to cart functionality
     */
    $('body').on('click', '.product-variant-increment', function(event){
      var value = parseInt($(".product-variant-quantity").val());
      $(".product-variant-quantity").val(value + 1);
    })
    $('body').on('click', '.product-variant-decrement', function(event){
      var value = parseInt($(".product-variant-quantity").val());
      if (value !== 1) {
        $(".product-variant-quantity").val(value - 1);
      }
    })

    $('select.variant-attribute-select').each(function() {
      var hidden = $(`<input type="hidden" name="${$(this).attr('name').split('_')[1]}">`);
      hidden.val($(this).val());
      hidden.insertAfter($(this));
    })

    var storeColors = JSON.parse($('#storeColors').val())
    if(storeColors.primaryColor && storeColors.secondaryColor) {
      $(`<style type='text/css'> .store-colors{ color:${storeColors.primaryColor}; border-color: ${storeColors.primaryColor}} </style>`).appendTo("head");
      $(`<style type='text/css'> .store-colors-selected{ color:${storeColors.secondaryColor}; background-color:${storeColors.primaryColor}; border-color: ${storeColors.primaryColor}} </style>`).appendTo("head");
    }

    const variantAvailable = (attributeValue, attributeOption) => {
      var attributes = JSON.parse($('#attributes').val())
      if (!attributes[attributeValue]) return false
      let available = true
      var selectedAttributes = $(`.variant-attribute[data-option!="${attributeOption}"].store-colors-selected`)
      for(let i = 0; i < selectedAttributes.length; i++) {
        if(!attributes[attributeValue].includes(parseInt(selectedAttributes[i].attributes['value'].value))) {
          available = false
          break
        }
      }
      return available
    }

    const updateProductPrice = () => {
      let variants = JSON.parse($('#restructured_variants').val())
      let selectedAttributes = $(`.variant-attribute.store-colors-selected`).map(function() {
        return parseInt(this.attributes['value'].value)
      }).toArray().sort()
      for(let i = 0; i < variants.length; i++) {
        if(JSON.stringify(variants[i].variant_attribute_ids.sort()) === JSON.stringify(selectedAttributes)) {
          $('.add-to-cart').data('variant_id', variants[i].product_variant_id)
          selectVariantImage(variants[i].product_variant_id)
          $('.listing_price').html(`${variants[i].price} ${$('.listing_price').data('currency')}`)
          if ($('.add-to-cart').prop('disabled')) {
            $('#get_notified').css('display', 'block')
          }
          else {
            $('#get_notified').css('display', 'none')
          }
          break
        }
      }
    }

    const selectVariantImage = (variant_id) => {
      let variantImage = $('.variant-below-image').filter(function(){
        return $(this).data('id') === variant_id
      })
      variantImage.first().click()
    }

    $("select option").unwrap().each(function() {
      let option = $(this).data('option')
      let value = parseInt($(this).val())
      var btn = $(`<div class="btn variant-attribute store-colors" data-option="${option}" value="${value}">` + $(this).text() + '</div>');
      if($(this).hasClass('selected')){
        if(!variantAvailable(value, option)) {
          $('.add-to-cart').html('Out of Stock')
          $('.add-to-cart').prop('disabled', true)
        }
        btn.addClass('store-colors-selected');
      }
      $(this).replaceWith(btn);
    }).promise().done( function(){ updateProductPrice() } );

    $('body').on('click', '.variant-attribute', function() {
      const value = $(this).attr('value')
      const option = $(this).data('option')
      if (!variantAvailable(value, option)) {
        $('.add-to-cart').html('Out of Stock')
        $('.add-to-cart').prop('disabled', true)
      }
      else if($('.add-to-cart').prop('disabled')) {
        $('.add-to-cart').html('Add to Cart')
        $('.add-to-cart').prop('disabled', false)
      }
      $(`.variant-attribute[data-option='${option}']`).removeClass('store-colors-selected')
      $(this).addClass('store-colors-selected')
      $(`input[name="${option}"]`).val(value)
      updateProductPrice()
    })

    function showErrorOnAddToCart(errorText) {
      $('.product-data .error-msg').text(errorText);
      $('.product-data .error-msg').slideDown();
      setTimeout(() => {
        $('.product-data .error-msg').slideUp();
      }, 5000);
    }

    $('body').on('click', '#get_notified', function(){
      let modal = $('#product_notify_modal')
      modal.css('display', 'block')
      modal.data('product', $('.add-to-cart').data('product'))
      modal.data('variant', $('.add-to-cart').data('variant_id'))
    })

    $('body').on('click', '.add-to-cart', function(){
      const attributeIds = []
      $('.variant-attribute.store-colors-selected').each(function() {
        attributeIds.push(parseInt($(this).attr('value')))
      })
      const variant_id = $('.add-to-cart').data('variant_id')
      const variant_quantity = parseInt($(".product-variant-quantity")[0].value)
      if(attributeIds.length <= 0) {
        showErrorOnAddToCart('Please select a variant before you are adding the product to your cart')
        return
      }
      const data = {
        product_id: $(this).data('product'),
        variant_attribute_ids: JSON.stringify(attributeIds),
        variant_id: variant_id,
        quantity: variant_quantity
      }
      // disable add to cart button
      $(this).addClass('disabled');
      const cartURL = `${window.STORE_BASE_URL}/cart`;
      $.post(cartURL, data, function(res){
        if(res.success){
          updateCartCount(res.data.total);
          // enable button
          $('.product-data .success-msg').slideDown();
          setTimeout(() => {
            $('.add-to-cart').removeClass('disabled');
          }, 2000);
          setTimeout(() => {
            $('.product-data .success-msg').slideUp();
          }, 5000);
        } else {
          // TODO show proper error
          showAjaxError(res);
        }
      }).fail(({ responseJSON })=> {
        showErrorOnAddToCart(responseJSON.message)
        setTimeout(() => {
          $('.add-to-cart').removeClass('disabled');
        }, 2000);
      })
    });
  }
  if ($('.team-checkout').length) {
    const userId = $('#team-container').data('user')
    if (userId) {
      $.get(`${window.STORE_BASE_URL}/users/${userId}/teams`, function(res) {
        $('#team-container').html(res)
      })
    }
  }
  
  /**
   * TM - Remove from Cart
   */
  $('body').on('click', '.remove-cart-item', function(e){
    e.preventDefault();
    $(this).parent().trigger("submit");
  });

  /**
   * Shopping cart address
   */

  $('body').on('click', '#address-select .address-container', function(e){
    const selected = $('#address-select').find('.selected')
    selected.removeClass('selected')
    $(this).addClass('selected')
    
    $('#address-form .address-id-field').val($(this).data('id'))
    const content = $('#address-form #address-form-content')
    content.slideUp('normal', function(){
      content.removeClass('active')
    })
  })

  $('body').on('click', '.new-address-btn', function(e){
    e.preventDefault()
    const selected = $('#address-select').find('.selected')
    selected.removeClass('selected')
    $('#address-form .address-id-field').val('')
    const content = $('#address-form #address-form-content')
    content.slideDown('slow', function(){
      content.addClass('active')
    })
  })
  $('body').on('change', '#address-form input[name="same_invoice_address"]', function(e){
    e.preventDefault()
    const content = $('#address-form #billing-addresses')
    if($(this).val() === 'true') {
      content.slideUp('noraml', function(){
        content.removeClass('active')
      })
      // Also hide the form if it is open
      $('#address-form #address-form-content').slideUp('noraml', function(){
        content.removeClass('active')
      })
    } else {
      if (!$(this).data('user')){
        $('#address-form #address-form-content').slideDown('normal', function(){
          content.addClass('active')
        })
      }
      content.slideDown('slow', function(){
        content.addClass('active')
      })
    }
  })

  /**
   * Shopping cart copy address
   */

  $('body').on('change', '#copy-address', function(e){
    if ($(this).is(':checked')) {
      // Copy delivery address values to invoice address fields
      $('#invoice_name').val($('#shipping_name').val());
      $('#invoice_address_one').val($('#shipping_address_one').val());
      $('#invoice_address_two').val($('#shipping_address_two').val());
      $('#invoice_postcode').val($('#shipping_postcode').val());
      $('#invoice_city').val($('#shipping_city').val());
      $('#invoice_country').val($('#shipping_country').val());
    }
  });
  const messages = {
    en: {
      discountApplied: 'Discount applied!',
      invalidCode: 'Invalid discount code!',
    },
    sv: {
      discountApplied: 'Rabatt tillämpad!',
      invalidCode: 'Ogiltig rabattkod!',
    },
  };
  /**
   * Shopping cart apply coupon
   */
  $('body').on('click', '#apply-coupon', function(e){
    e.preventDefault()
    const cartId = $(this).data('cartid')
    const cartURL = `${window.STORE_BASE_URL}/cart/${cartId}/apply-coupon`
    const coupon = $('input#coupon').val()
    const currentLanguage = getCookie('i18next') || 'en'
    $.post(cartURL, {coupon: coupon}, function(res) {
      if (res.valid) {
        $('.helptext').removeClass('error').addClass('success').text(messages[currentLanguage].discountApplied)
        setTimeout(function() {
          window.location.reload()
        }, 1000)
      } else {
        $('.helptext').removeClass('success').addClass('error').text(messages[currentLanguage].invalidCode)
      }
    })
  })
  /**
   * Save cart item quantity change
   */
  $('body').on('blur', '.cart-item-quantity', function(e){
    e.preventDefault()
    updateQuantity(this)
  })
  $('body').on('keyup', '.cart-item-quantity', function(e){
    // if Enter is pressed update the quantity
    if (e.keyCode === 13) {
      e.preventDefault()
      updateQuantity(this)
    }
  })
  /**
   * Handle user account credit contribution on checkout page
   */
  function handleUseBalance(input) {
    const remainingAmount = parseInt(input.data('remaining'))
    if (input.is(':checked')) {
      $('.balance-info').slideDown()
      if (remainingAmount < 1) {
        $('#payment-section').addClass('disable-payment')
        $('#payment-section input').attr('disabled', true)
      }
    } else {
      $('.balance-info').slideUp()
      $('#payment-section').removeClass('disable-payment')
      $('#payment-section input').attr('disabled', false)
    }
  }

  const balanceInput = $('#balance-input')
  // on load trigger the flow if user has already checked the input and went back.
  handleUseBalance(balanceInput)

  balanceInput.on('change', function(e){
    handleUseBalance($(this))
  })
});
// Validate forms
$('#cart #address-form').validate({
  errorPlacement: function(error, element) {
        // Don't show error
  },
});
// $('#cart #checkout-form').validate({
//   errorPlacement: function(error, element) {
//         // Don't show error
//   },
// });

function fetchCartCount(){
  var cartURL = `${window.STORE_BASE_URL}/cart/count`;
  $.post(cartURL, {}, function(res, status){
    if (res.success) {
      updateCartCount(res.data.total);
    } else if (status !== 'success') {
      // TODO show proper error
      showAjaxError(res);
    }
  });
}
function updateCartCount(count){
  const counter = $('body').find('.cart-link .cart-count').first()
  counter.text(count > 0 ? `${count}` : '');
  const badge = $('body').find('.cart-link .cart-badge-wraper').first()
  if(!badge.is(':visible') && count > 0) {
    badge.fadeIn()
  } else if ( count <= 0) {
    badge.fadeOut()
  }
}
function updateQuantity(el) {
  const cartId = $(el).data('cartid')
  const cartItemId = $(el).data('cart-item')
  const cartURL = `${window.STORE_BASE_URL}/cart/${cartId}/update-quantity/${cartItemId}`
  const quantity = $(el).val()
  $.post(cartURL, {quantity: quantity}, function(res) {
    window.location.reload()
  })
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
