$(document).ready(function () {
    if ($('#start').length) {
        
        $('.splash-slider').slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            dots: true,
            fade: true
        });

        // On page scroll change header menu
        // If splash images slider is present then enable scroll functionality otherwise show header
        // Remove transparent header feature as it could be unconsistent based on different background images
        // if($('.splash-slider').length) {
        //     toggleTopNave();
        //     $(window).scroll(function(){
        //         toggleTopNave();
        //     });
        // } else {
        //     $('#header').addClass('default');
        // }
    }    

});

function toggleTopNave() {
    var splashOffset = $('.main-container').offset().top;
    var scroll = $(window).scrollTop();
    // Slider dots section should also be included in distance 
    // as its white background so header should be converted at this part as well
    var slideDots = $('.slick-dots').outerHeight();
    slideDots = slideDots ? slideDots : 0;
    var splashDistance = splashOffset - slideDots - scroll;
    var header = $('.top-navigation').outerHeight();
    var headerSplashDistance = splashDistance - header;
    // If add default class to header as we scroll down
    if (headerSplashDistance <= 0) {
        $('#header').addClass('default');
    }
    else {
        $('#header').removeClass('default');
    }
}
