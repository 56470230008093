import { HttpClient } from "./httpClient"

$(document).ready(function () {
    function initializeProductContainer(selector, productsSelector, urlPath) {
        if ($(selector).length) {
            var productsLoadMoreButton;
            var productsContainer = $(`${productsSelector}-products`);
            var productsPerPage = productsContainer.data('products_per_page');
            var entity = productsContainer.data('id');
            var products_url = window.STORE_BASE_URL + urlPath + entity + '/products';
            var sortingSelect = $('#product-sorting-select');

            initializeProducts(productsContainer, products_url, productsLoadMoreButton, productsPerPage, sortingSelect);
        }
    }
    function initializeProducts(productsContainer, products_url, productsLoadMoreButton, productsPerPage, sortingSelect) {
        if (productsContainer.length) {
            var count = productsContainer.data('count');

            $('body').on('click', '.more-products a', function (e) {
                var page = $(this).data('page');
                var sort_by_parameter = sortingSelect.val();
                HttpClient.get(products_url, { page: page, sort_by: sort_by_parameter }, function (response) {
                    $('.products-container').append(response);
                    $('.more-products a.load-more').first().data('page', page + 1);
                    var products_loaded = page * productsPerPage;
                    $('.more-products .show-more-count').text(`(${count - products_loaded})`);
                    if (products_loaded >= count) {
                        productsLoadMoreButton = $('.more-products').detach();
                    }
                });
            });

            sortingSelect.on('change', function (event) {
                var count = productsContainer.data('count');
                var productsPerPage = productsContainer.data('products_per_page');

                HttpClient.get(products_url, { sort_by: this.value }, function (response) {
                    $('.products-container').empty();
                    $('.products-container').append(response);
                    if (!jQuery.contains(document, $('.more-products')[0])) {
                        productsContainer.append(productsLoadMoreButton);
                    }
                    $('.more-products a.load-more').first().data('page', 2);
                    $('.more-products .show-more-count').text(`(${count - productsPerPage})`);
                });
            });
        }
    }

    initializeProductContainer('#category', '.cat', '/categories/')
    initializeProductContainer('#collection', '.col', '/collections/')
});
