import $ from 'jquery';

const processErrors = xhr => {
  if (xhr.status === 401) {
    if (xhr.responseJSON && xhr.responseJSON.redirect) {
      window.location.href = xhr.responseJSON.redirect;
    } else {
      alert('Your session seems to be expired, please login again.');
      window.location.href = '/';
    }
  } else {
    throw xhr;
  }
};

const get = (url, data = {}, config = {}) => {
  return $.get(url, data, config).fail(processErrors);
};

const post = (url, data = {}, config = {}) => {
  return $.post(url, data, config).fail(processErrors);
};

const put = (url, data = {}, config = {}) => {
  return $.ajax({ url, type: 'PUT', data, ...config }).fail(processErrors);
};

const del = (url, data = {}, config = {}) => {
  return $.ajax({ url, type: 'DELETE', data, ...config }).fail(processErrors);
};

const HttpClient = {
  get,
  post,
  put,
  delete: del
};

export { HttpClient };