$(document).on('click', '#prod-img .big-inner .images-slides ul li, #prod-img .zoom-pic', function (e) {
    var initialSlide, sliderContainer, slides, thumbnails, thumbnailsContainer, zoomBox;
    zoomBox = $('<div/>', {
        "class": 'zoom-box no-close'
    });
    sliderContainer = $('<div/>', {
        id: 'product-images-zoom',
        "class": 'text-center'
    });
    initialSlide = 0;
    slides = $('<ul/>', {
        id: 'slider'
    });
    thumbnails = $('<ul/>', {
        id: 'slider-nav'
    });
    $('#prod-img .colors ul li').each(function (index) {
        var li;
        if ($(this).hasClass('slick-current')) {
            initialSlide = index;
        }
        li = $('<li/>').append($(this).html());
        li.find('img').prop('srcset', $(this).data('zoom-image-thumb')); // for bigger thumbnails
        thumbnails.append(li);
        li = li.clone();
        li.find('img').prop('src', $(this).data('zoom-image'));
        li.find('img').prop('srcset', $(this).data('zoom-image'));
        // create a picture element
        console.log($(this).data('zoom-image-webp'));
        if ($(this).data('zoom-image-webp') !== '' && $(this).data('zoom-image-webp') !== undefined) {
            var picture = $('<picture/>');
            var source = $('<source/>');
            source.prop('srcset', $(this).data('zoom-image-webp'));
            picture.append(source);
            picture.append(li.find('img'));
            // replace the img with the picture element
            li.find('.inner').html(picture);
        }
        li = $('<li/>').append(li.html());
        return slides.append(li);
    });
    sliderContainer.append(slides);
    thumbnailsContainer = $('<div/>', {
        "class": 'thumbs-nav'
    });
    thumbnailsContainer.append(thumbnails);
    sliderContainer.append(thumbnailsContainer);
    zoomBox.append(sliderContainer);
    zoomBox.append($('<span/>', {
        "class": 'icon-cross close-icon'
    }));
    $('body#product').addClass('noscroll').append(zoomBox);
    $('#product-images-zoom #slider').slick({
        initialSlide: initialSlide,
        asNavFor: $('#product-images-zoom #slider-nav'),
        prevArrow: '<div class="arrow-left valign-container"><span class="icon-arrow-left-center"></span></div>',
        nextArrow: '<div class="arrow-right valign-container"><span class="icon-arrow-right-center"></span></div>',
    });
    $('#product-images-zoom #slider-nav').slick({
        infinite: false,
        slidesToShow: 18,
        asNavFor: $('#product-images-zoom #slider'),
        focusOnSelect: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 10
                }
            }, {
                breakpoint: 767,
                settings: {
                    slidesToShow: 6
                }
            }
        ]
    });
    return $('#product-images-zoom #slider').slick('slickGoTo', initialSlide);
});

$(document).on('click', '.zoom-box .close-icon', function (e) {
    return $('body').removeClass('noscroll').find('.zoom-box').remove();
});

$(document).ready(function () {
    var colorId, initialSlide, match;
    if ($('#prod-img').length) {
        initialSlide = 0;
        if (/[?&]color=([0-9]+(-[0-9]+)*)/.test(window.location.search)) {
            match = RegExp('[?&]color=([0-9]+(-[0-9]+)*)').exec(window.location.search);
            colorId = match[1];
            if ($("#prod-img .colors ul li[data-color=" + colorId + "]").length) {
                initialSlide = $("#prod-img .colors ul li[data-color=" + colorId + "]").index();
            }
        }
        $('#prod-img .big-inner .images-slides ul').slick({
            initialSlide: initialSlide,
            arrows: false,
            asNavFor: $('#prod-img .colors ul')
        });
        $('#prod-img .colors ul').slick({
            infinite: false,
            slidesToShow: 10,
            asNavFor: $('#prod-img .big-inner .images-slides ul'),
            focusOnSelect: true,
            prevArrow: '<div class="arrow-left valign-container"><span class="icon-arrow-left-center"></span></div>',
            nextArrow: '<div class="arrow-right valign-container"><span class="icon-arrow-right-center"></span></div>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 8
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 6
                    }
                }
            ]
        });
        $('#prod-img .big-inner .images-slides').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            if ($(slick.$slides[nextSlide]).find('img').attr('data-color')) {
                return $('#prod-img .big-inner .color-label').text($(slick.$slides[nextSlide]).find('img').data('color'));
            } else {
                return $('#prod-img .big-inner .color-label').text('');
            }
        });
        return $('#prod-img .big-inner .images-slides ul').slick('slickGoTo', initialSlide, true);
    }
});
